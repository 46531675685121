import { render } from "./index.vue?vue&type=template&id=b8a289ae&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"

import "./index.vue?vue&type=style&index=0&id=b8a289ae&lang=less&scoped=true"
script.render = render
script.__scopeId = "data-v-b8a289ae"
/* hot reload */
if (module.hot) {
  script.__hmrId = "b8a289ae"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('b8a289ae', script)) {
    api.reload('b8a289ae', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=b8a289ae&scoped=true", () => {
    api.rerender('b8a289ae', render)
  })

}

script.__file = "src/views/iframeDashboard/index.vue"

export default script