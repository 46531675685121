import { render } from "./NantouMapChart.vue?vue&type=template&id=32b0f2f3&scoped=true"
import script from "./NantouMapChart.vue?vue&type=script&setup=true&lang=js"
export * from "./NantouMapChart.vue?vue&type=script&setup=true&lang=js"

import "./NantouMapChart.vue?vue&type=style&index=0&id=32b0f2f3&scoped=true&lang=css"
script.render = render
script.__scopeId = "data-v-32b0f2f3"
/* hot reload */
if (module.hot) {
  script.__hmrId = "32b0f2f3"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('32b0f2f3', script)) {
    api.reload('32b0f2f3', script)
  }
  
  module.hot.accept("./NantouMapChart.vue?vue&type=template&id=32b0f2f3&scoped=true", () => {
    api.rerender('32b0f2f3', render)
  })

}

script.__file = "src/views/index/components/NantouMapChart.vue"

export default script