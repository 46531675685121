import request from '@/utils/iframeRequest'

export async function getClientDistrictAbnormal(token, data) {
  //console.log(params)

  return request({
    url: `/statistics.client.group.abnormal/${data.startTs}/${data.endTs}`,
    method: 'get',
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
  })
}

export async function getClientCountryDistrictAbnormal(token, data) {
  //console.log(params)

  return request({
    url: `/statistics.client.group.abnormal/${data.city}/${data.district}/${data.startTs}/${data.endTs}`,
    method: 'get',
    // headers: {
    //   Authorization: `Bearer ${token}`,
    // },
  })
}
