<template>
  <div>
    <ECharts
      class="chart"
      ref="map"
      :option="option"
      :update-options="{
        notMerge: true,
      }"
      @selectchanged="selectchanged"
      @click="onClick"
      autoresize
    />
  </div>
</template>

<script setup>
  /* eslint-disable no-unused-vars*/
  import { defineProps, computed, ref, watch, defineEmits } from 'vue'
  import { registerMap } from 'echarts'
  import { use } from 'echarts/core'
  import { MapChart } from 'echarts/charts'
  import ECharts from 'vue-echarts'
  import geoJsonNantou from './geojson/nantou.json'

  use([MapChart])
  const map = ref(null)
  const props = defineProps(['districtData', 'title'])
  registerMap('nantou', geoJsonNantou)

  const emit = defineEmits(['clickCard'])

  let data = [
    { value: 0, count: 0, rate: '0%', name: '南投市' },
    { value: 0, count: 0, rate: '0%', name: '名間鄉' },
    { value: 0, count: 0, rate: '0%', name: '埔里鎮' },
    { value: 0, count: 0, rate: '0%', name: '集集鎮' },
    { value: 0, count: 0, rate: '0%', name: '魚池鄉' },
    { value: 0, count: 0, rate: '0%', name: '中寮鄉' },
    { value: 0, count: 0, rate: '0%', name: '信義鄉' },
    { value: 0, count: 0, rate: '0%', name: '草屯鎮' },
    { value: 0, count: 0, rate: '0%', name: '水里鄉' },
    { value: 0, count: 0, rate: '0%', name: '竹山鎮' },
    { value: 0, count: 0, rate: '0%', name: '鹿谷鄉' },
    { value: 0, count: 0, rate: '0%', name: '國姓鄉' },
    { value: 0, count: 0, rate: '0%', name: '仁愛鄉' },
  ]

  const option = computed(() => {
    data.forEach((item) => {
      item.value = 0
      item.count = 0
      item.rate = '0%'
      if (props.districtData && props.districtData.length) {
        const found = props.districtData.find(
          (districtItem) => item.name === districtItem.name
        )
        item.value = found?.abnormalCount | 0
        item.count = found?.totalCount | 0
        item.rate = found?.rate
      }
    })

    const maxValue = data
      .map((v) => v.value)
      .reduce((max, v) => Math.max(max, v), 0)
    // console.log(data)

    return {
      emit,
      // title: {
      //   text: props.title,
      //   left: 'center',
      // },
      // https://echarts.apache.org/en/option.html#tooltip
      tooltip: {
        trigger: 'item',
        // https://echarts.apache.org/en/option.html#tooltip.formatter
        formatter: (params) => {
          const { value } = params
          if (params?.data?.count > 0) {
            return `異常人次 ${isNaN(value) ? 0 : value} 人 <br/> 異常比 ${
              params?.data?.rate
            }`
          } else {
            return '無量測紀錄'
          }
        },
      },
      // https://echarts.apache.org/en/option.html#visualMap
      visualMap: {
        min: 0,
        max: maxValue,
        text: ['High', 'Low'],
        realtime: false,
        calculable: false,
        inRange: {
          color: ['lightgray', 'yellow', 'orangered'],
        },
      },
      series: [
        {
          // https://echarts.apache.org/en/option.html#series-map
          name: props.title,
          type: 'map',
          map: 'nantou',
          data,
          label: { show: true, fontSize: 10 },
          nameProperty: 'TOWN',
          selectedMode: false,
        },
      ],
    }
  })
  const onClick = (e) => {
    if (e?.data?.count > 0) {
      emit('clickCard', e.name)
    }
  }

  watch(
    () => props.dealerBarPerType,
    (current, prev) => {}
  )
</script>

<style scoped>
  .chart {
    height: 80vh;
  }
</style>
